// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'apse2-slsstg',
  env: 'stg',
  languageEnvironment: 'staging',
  aws: {
    region: 'ap-southeast-2',
    identityPoolId: 'ap-southeast-2:cc27f831-2a8d-4409-8edf-c0fd488ae78d',
    bucket: 'etrainu-assessment-app-signup-image-storage-apse2-slsstg',
    apiVersion: '2006-03-01'
  },
  api: {
    createEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events',
    updateEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}',
    cancelEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/cancel',
    completeEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/complete',
    reviewEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/review',
    saveComment: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/review/comments',
    approveEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/approve',
    rejectEvent: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/reject',
    requestChanges: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/review/request-changes',
    listEvents: 'https://stgsls.api.etrainu.com/events-view/v1',
    singleEvent: 'https://stgsls.api.etrainu.com/events-view/v1/{id}',
    attendanceSheet: 'https://stgsls.api.etrainu.com/events-view/v1/{id}/attendance',
    signInParticipant: 'https://stgsls.api.etrainu.com/events-register/v1/{id}/register/{sessionId}',
    enrolParticipant: 'https://stgsls.api.etrainu.com/events-enrol/v1/{id}/enrol',
    participantDetails: 'https://stgsls.api.etrainu.com/events-view/v1/{id}/participants?output={outputType}',
    getParticipantDetails: 'https://stgsls.api.etrainu.com/events-view/v1/{id}/participants/{memberId}',
    updateAssessmentStatus: 'https://stgsls.api.etrainu.com/events-assess/v1/{id}/participants/{participantId}/assess',
    bulkUpdateAssessmentStatus: 'https://stgsls.api.etrainu.com/events-assess/v1/{id}/participants/assess',
    markAllAssessmentsAsComplete: 'https://stgsls.api.etrainu.com/events-assess/v1/{id}/participants/{participantId}/course/{courseId}/complete',
    resetAllAssessments: 'https://stgsls.api.etrainu.com/events-assess/v1/{id}/participants/{participantId}/course/{courseId}/reset',
    login: 'https://stgsls.api.etrainu.com/auth/v1/login',
    summary: 'https://stgsls.api.etrainu.com/events-view/v1/{id}/summary',
    externalMemberSearch: 'https://stgsls.api.etrainu.com/slsa/v1/members/search',
    internalMemberSearch: 'https://stgsls.api.etrainu.com/users/v1/find/',
    getAssessors: 'https://stgsls.api.etrainu.com/events-admin/v1/events/assessors',
    getTrainers: 'https://stgsls.api.etrainu.com/events-admin/v1/events/trainers',
    getReviewers: 'https://stgsls.api.etrainu.com/events-admin/v1/events/reviewers',
    findCreateMember: 'https://stgsls.api.etrainu.com/auth/v1/users',
    courseSearch: 'https://stgsls.api.etrainu.com/courses/v1/search',
    courseAccess: 'https://stgsls.api.etrainu.com/courses/v1/has-access',
    partnershipSearch: 'https://stgsls.api.etrainu.com/accounts/v1/{id}/hierarchy/{hierarchyLevel}',
    getAccountHierarchy: 'https://stgsls.api.etrainu.com/accounts/v1/{id}/hierarchy',
    findUsersByExternalSystemId: 'https://stgsls.api.etrainu.com/users/v1/find',
    sendNotification: 'https://stgsls.api.etrainu.com/events-notifications/v1/send',
    sendNotificationV2: 'https://stgsls.api.etrainu.com/events-notifications/v2/send',
    removeParticipant: 'https://stgsls.api.etrainu.com/events-register/v1/{id}/participants/{participantId}',
    getOrganisation: 'https://stgsls.api.etrainu.com/organisations/v1/{id}',
    getOrganisationByDomain: 'https://stgsls.api.etrainu.com/organisations/v1/domain',
    getOrganisationList: 'https://stgsls.api.etrainu.com/organisations/v1',
    eventEligibility: 'https://stgsls.api.etrainu.com/events-enrol/v1/{id}/enrol/eligible',
    creditEligibility: 'https://stgsls.api.etrainu.com/events-assess/v1/{id}/participants/{participantId}/check-rpl',
    userCoursesEligibility: 'https://stgsls.api.etrainu.com/events-enrol/v1/{id}/participants/{participantId}/courses/eligible',
    checkCourseEnrolment: 'https://stgsls.api.etrainu.com/events-enrol/v1/{id}/participants/{participantId}/courses',
    complianceReport: 'https://stgsls.api.etrainu.com/events-view/v1/{eventId}/compliance',
    saveEventDocument: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/documents',
    presignedUploadUrl: 'https://stgsls.api.etrainu.com/events-admin/v1/events/{id}/documents/{documentId}',
    presignedDownloadUrl: 'https://stgsls.api.etrainu.com/events-view/v1/{eventId}/documents/{documentId}',
    presignedDownloadAllUrl: 'https://stgsls.api.etrainu.com/events-view/v1/{eventId}/documents',
    generateEnrolmentUrl: 'https://stgsls.api.etrainu.com/events-enrol/v1/{eventId}/enrolment-url',
    updateMemberEnrol: 'https://stgsls.api.etrainu.com/events-enrol/v1/{id}/participants/{participantId}'
  },
  static: {
    organisations: [
      {
        orgId: 'DDzRHdi4UHAczFKLG7H4Wz1He84e7uAx',
        name: 'etrainu',
      },
    ],
    organisationAppConfig: [
      {
        name: 'SLSA',
        currentVersion: '1.4.16',
        appStoreLinks :
          {
            ios: 'https://apps.apple.com/au/app/assessing/id1420303828',
            android: 'https://play.google.com/store/apps/details?id=com.etrainu.assess.sls'
          }
      },
    ],
    reviewersConfig: [
      {
        name: 'SLSA',
        allStageOneReviewers:'kumha5qgLDtlTVWcisjA33aCwL32pfUZ',
        allStageTwoReviewers:'bEQDjYU2mWEbCdHMQQQB8OsT0jmNYgOT',
        all_stage_1_reviewers: 'all_stage_1_reviewers',
        all_stage_2_reviewers: 'all_stage_2_reviewers',
      }
    ]
  },
  external: {
    gmapsApi: 'AIzaSyCuDshxXKg5G3ey_76czckDo61D_1Utcdo'
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
